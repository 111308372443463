import React from 'react';
import { AppRouter } from './app-router';
import './app.scss';

export class App extends React.Component {
  render() {
    return (
      <AppRouter />
    )
  }
}
